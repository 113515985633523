import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import { PackageMetadata, BackgroundBox } from "@happeo/docs-tools";
import pkg from "../package.json";
import { IconHome } from "@happeouikit/icons";
import { LiStriped, ListStripedContainer, Ul, ListHeader, UserDetails, ExpandableList } from "../src/index";
import { StripedListExample, ListWithMarginsExample } from "../src/Examples";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "list"
    }}>{`List`}</h1>
    <PackageMetadata pkg={pkg} mdxType="PackageMetadata" />
    <h3 {...{
      "id": "striped-list"
    }}>{`Striped List`}</h3>
    <Playground __position={1} __code={'<StripedListExample />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      BackgroundBox,
      pkg,
      IconHome,
      LiStriped,
      ListStripedContainer,
      Ul,
      ListHeader,
      UserDetails,
      ExpandableList,
      StripedListExample,
      ListWithMarginsExample,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StripedListExample mdxType="StripedListExample" />
    </Playground>
    <h3 {...{
      "id": "list-with-margins"
    }}>{`List with margins`}</h3>
    <BackgroundBox mdxType="BackgroundBox">
  <ListWithMarginsExample mdxType="ListWithMarginsExample" />
    </BackgroundBox>
    <h3 {...{
      "id": "user-details"
    }}>{`User details`}</h3>
    <Playground __position={3} __code={'<UserDetails user={{ fullName: \'Test User\', id: 1 }} />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      BackgroundBox,
      pkg,
      IconHome,
      LiStriped,
      ListStripedContainer,
      Ul,
      ListHeader,
      UserDetails,
      ExpandableList,
      StripedListExample,
      ListWithMarginsExample,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UserDetails user={{
        fullName: "Test User",
        id: 1
      }} mdxType="UserDetails" />
    </Playground>
    <h3 {...{
      "id": "expandable-list"
    }}>{`Expandable List`}</h3>
    <Playground __position={4} __code={'() => {\n  const menuItems = [\n    {\n      label: \'General\',\n      id: \'general\',\n      href: \'/general\',\n    },\n    {\n      label: \'Analytics\',\n      id: \'analytics-1\',\n      subs: [\n        {\n          label: \'Overview\',\n          id: \'overview\',\n          href: \'/overview\',\n          subs: [\n            {\n              label: \'Statistics\',\n              id: \'statistics\',\n              subs: [],\n            },\n            {\n              label: \'Page Views\',\n              id: \'page-views\',\n              subs: [\n                {\n                  label: \'Blog\',\n                  id: \'blog\',\n                  subs: [],\n                },\n              ],\n            },\n          ],\n        },\n        {\n          label: \'Pages engagement\',\n          id: \'pages-engagement\',\n        },\n        {\n          label: \'Channels engagement\',\n          id: \'channels-engagement\',\n        },\n      ],\n    },\n    {\n      label: \'User management\',\n      id: \'user-management\',\n    },\n    {\n      label: \'Channels\',\n      id: \'channels\',\n      subs: [\n        {\n          label: \'Statistics\',\n          id: \'statistics\',\n          subs: [],\n        },\n      ],\n    },\n  ]\n  return (\n    <ExpandableList\n      items={menuItems}\n      handleClick={item => console.log(\'CLICKED \', item.id)}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      BackgroundBox,
      pkg,
      IconHome,
      LiStriped,
      ListStripedContainer,
      Ul,
      ListHeader,
      UserDetails,
      ExpandableList,
      StripedListExample,
      ListWithMarginsExample,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const menuItems = [{
          label: "General",
          id: "general",
          href: "/general"
        }, {
          label: "Analytics",
          id: "analytics-1",
          subs: [{
            label: "Overview",
            id: "overview",
            href: "/overview",
            subs: [{
              label: "Statistics",
              id: "statistics",
              subs: []
            }, {
              label: "Page Views",
              id: "page-views",
              subs: [{
                label: "Blog",
                id: "blog",
                subs: []
              }]
            }]
          }, {
            label: "Pages engagement",
            id: "pages-engagement"
          }, {
            label: "Channels engagement",
            id: "channels-engagement"
          }]
        }, {
          label: "User management",
          id: "user-management"
        }, {
          label: "Channels",
          id: "channels",
          subs: [{
            label: "Statistics",
            id: "statistics",
            subs: []
          }]
        }];
        return <ExpandableList items={menuItems} handleClick={item => console.log("CLICKED ", item.id)} mdxType="ExpandableList" />;
      }}
    </Playground>
    <h3 {...{
      "id": "expandable-list-with-custom-child"
    }}>{`Expandable List with custom child`}</h3>
    <Playground __position={5} __code={'() => {\n  const menuItems = [\n    {\n      label: \'General\',\n      id: \'general\',\n      href: \'/general\',\n      subs: [\n        {\n          id: \'custom-component\',\n          href: \'/custom-component\',\n          element: <UserDetails user={{ fullName: \'Test User\', id: 1 }} />,\n          subs: [\n            {\n              id: \'nested-element\',\n              label: \'Nested element\',\n            },\n          ],\n        },\n      ],\n    },\n    {\n      label: \'Analytics\',\n      id: \'analytics-2\',\n    },\n  ]\n  return <ExpandableList items={menuItems} />\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      BackgroundBox,
      pkg,
      IconHome,
      LiStriped,
      ListStripedContainer,
      Ul,
      ListHeader,
      UserDetails,
      ExpandableList,
      StripedListExample,
      ListWithMarginsExample,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const menuItems = [{
          label: "General",
          id: "general",
          href: "/general",
          subs: [{
            id: "custom-component",
            href: "/custom-component",
            element: <UserDetails user={{
              fullName: "Test User",
              id: 1
            }} mdxType="UserDetails" />,
            subs: [{
              id: "nested-element",
              label: "Nested element"
            }]
          }]
        }, {
          label: "Analytics",
          id: "analytics-2"
        }];
        return <ExpandableList items={menuItems} mdxType="ExpandableList" />;
      }}
    </Playground>
    <h3 {...{
      "id": "expandable-list-with-custom-icon"
    }}>{`Expandable List with custom icon`}</h3>
    <Playground __position={6} __code={'() => {\n  const menuItems = [\n    {\n      label: \'Analytics\',\n      id: \'analytics-3\',\n      icon: <IconHome />,\n      subs: [\n        {\n          label: \'Overview\',\n          id: \'overview\',\n          href: \'/overview\',\n          subs: [\n            {\n              label: \'Statistics\',\n              id: \'statistics\',\n              subs: [],\n            },\n            {\n              label: \'Page Views\',\n              id: \'page-views\',\n              subs: [\n                {\n                  label: \'Blog\',\n                  id: \'blog\',\n                  subs: [],\n                },\n              ],\n            },\n          ],\n        },\n        {\n          label: \'Pages engagement\',\n          id: \'pages-engagement\',\n        },\n        {\n          label: \'Channels engagement\',\n          id: \'channels-engagement\',\n        },\n      ],\n    },\n    {\n      label: \'Analytics\',\n      id: \'analytics-4\',\n    },\n  ]\n  return <ExpandableList items={menuItems} />\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      BackgroundBox,
      pkg,
      IconHome,
      LiStriped,
      ListStripedContainer,
      Ul,
      ListHeader,
      UserDetails,
      ExpandableList,
      StripedListExample,
      ListWithMarginsExample,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const menuItems = [{
          label: "Analytics",
          id: "analytics-3",
          icon: <IconHome mdxType="IconHome" />,
          subs: [{
            label: "Overview",
            id: "overview",
            href: "/overview",
            subs: [{
              label: "Statistics",
              id: "statistics",
              subs: []
            }, {
              label: "Page Views",
              id: "page-views",
              subs: [{
                label: "Blog",
                id: "blog",
                subs: []
              }]
            }]
          }, {
            label: "Pages engagement",
            id: "pages-engagement"
          }, {
            label: "Channels engagement",
            id: "channels-engagement"
          }]
        }, {
          label: "Analytics",
          id: "analytics-4"
        }];
        return <ExpandableList items={menuItems} mdxType="ExpandableList" />;
      }}
    </Playground>
    <h3 {...{
      "id": "listheader-props"
    }}>{`ListHeader props`}</h3>
    <Props of={ListHeader} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      